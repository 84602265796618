import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './Login.css'

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        // const apiUrl = process.env.REACT_APP_API_URL;

        try {
            const response = await fetch('https://oskbolid.pl/api.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    action: 'login',
                    username,
                    password
                })
            });

            const result = await response.json();
            
            if (result.status === 'success') {
                localStorage.setItem('isLoggedIn', 'true');
                navigate('/admin');
            } else {
                setError('Niepoprawne dane logowania');
            }
        } catch (error) {
            setError('Wystąpił błąd');
        }
    };

    return (
      <div className="login-container">
        <form onSubmit={handleSubmit}>
          <h2>Logowanie</h2>
          <p>Wprowadź swoje dane logowania poniżej:</p>
          <label htmlFor="username">Nazwa użytkownika</label>
          <input type="text" id="username" name="username" placeholder="Nazwa administratora" required value={username} onChange={(e) => setUsername(e.target.value)}/>

          <label htmlFor="password">Hasło</label>
          <input type="password" id="password" name="password" value={password} placeholder="Hasło" onChange={(e) => setPassword(e.target.value)} required/>

        
          {error && <p className="error">{error}</p>}
          <button type="submit">
            Zaloguj się
          </button>
        </form>
      </div>
    );
}

export default Login;
