import React, { useState, useEffect } from 'react'

import './MainPage.css';
import { Menu, Home, AboutUs, Offer, Team, Footer, PassingRate, MapAndSignUp } from '../../sections';

function MainPage() {
  const [courseDate, setCourseDate] = useState('');
  const [meetingDate, setMeetingDate] = useState('');
  const [standardPrice, setStandardPrice] = useState('');
  const [premiumPrice, setPremiumPrice] = useState('');
  const [additionalDriving, setAdditionalDriving] = useState('');
  
  const [date, setDate] = useState({
    courseDate: '',
    meetingDate: '',
    standardPrice: '',
    premiumPrice: '',
    additionalDriving: '',
  });


  useEffect(() => {
    // const apiUrl = process.env.REACT_APP_API_URL;
    const fetchData = async () => {
        try {
            const response = await fetch('https://oskbolid.pl/api.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ action: 'get_data' })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.text();

            try {
                const jsonData = JSON.parse(data);
                setCourseDate(jsonData.courseDate || '');
                setMeetingDate(jsonData.meetingDate || '');
                setStandardPrice(jsonData.standardPrice || '');
                setPremiumPrice(jsonData.premiumPrice || '');
                setAdditionalDriving(jsonData.additionalDriving || '');
                setDate({
                  courseDate,
                  meetingDate,
                  standardPrice,
                  premiumPrice,
                  additionalDriving,
                })
                
            } catch (jsonError) {
                alert('Błąd w analizie danych JSON');
            }
        } catch (error) {
            alert(`Wystąpił błąd: ${error.message}`);
        }
    };

    fetchData();


  }, [additionalDriving, courseDate, meetingDate, premiumPrice, standardPrice]);


    return (
      <div className="App">
        <Menu />
        <section id="home">
          <Home date={date}/>
        </section>
        <section id="about">
          <AboutUs />
        </section>
        <section>
          <PassingRate />
        </section>
        <section id="team">
          <Team />
        </section>
        <section id="offer">
          <Offer date={date}/>
        </section>
        <section id="contact">
          <MapAndSignUp />
        </section>
        <section>
          <Footer />
        </section>
      </div>
    );
}

export default MainPage;
