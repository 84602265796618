import { img1, img2, img3, img4, checklist, debitCard, grupa, plac, voucher1, voucher2, voucher3, voucher4 } from '../assets/images/index.js';

export const tableOfPersons = [
  {id: 4, name: 'Mateusz', jpg: img3},
  {id: 1, name: 'Barbara', jpg: img2},
  {id: 2, name: 'Roman', jpg: img4},
  {id: 3, name: 'Sebastian', jpg: img1},
]

// export const tableOfOffers = [
//   {id: 1, title: 'Standard', price: '3600 zł', information: [
//     {infoID: 1, text: '30h zajęć teoretycznych'},
//     {infoID: 3, text: '30h zajęć praktycznych'},
//     {infoID: 2, text: 'Materiały dydaktyczne (podręcznik oraz portal z testami)'},
//     {infoID: 4, text: 'Egzamin wewnętrzny'},
//   ]},
//   {id: 2, title: 'Premium', price: '4300 zł', information: [
//     {infoID: 1, text: '30h zajęć teoretycznych'},
//     {infoID: 3, text: '40h zajęć praktycznych'},
//     {infoID: 2, text: 'Materiały dydaktyczne (podręcznik oraz portal z testami)'},
//     {infoID: 4, text: 'Egzamin wewnętrzny'},
//     {infoID: 6, text: '+', class: true},
//     {infoID: 5, text: 'Uchwyt samochodowy do telefonu'},
//   ]},
//   {id: 3, title: 'Jazdy dodatkowe', price: '110 zł', information: [
//     {infoID: 1, text: `Szkolenie uzupełniające`},
//     {infoID: 2, text: `dopasowane indywidualnie`},
//     {infoID: 3, text: `do potrzeb klienta`},
//   ]},
// ]

export const tableOfFeature = [
  {id: 1, title: 'Grupy max. 12 osobowe', description: 'Od lat stawiamy jakość ponad ilość.', jpg: grupa},
  {id: 2, title: 'Toyota Yaris III', description: 'Pojazdy szkoleniowe takie jak na egzaminie państwowym.', jpg: checklist},
  {id: 3, title: 'Plac manewrowy', description: 'Zadaszony i oświetlony plac na Teranach Targowych Wirek.', jpg: plac},
  {id: 4, title: 'Płatność', description: 'Możliwość rozłożenia kosztów do pięciu nieoprocentowanych rat.', jpg: debitCard},
]

export const tableOfVoucher = [ voucher1, voucher2, voucher3, voucher4 ]
