import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './pages/AuthContext';

import MainPage from './pages/MainPage/MainPage';
import Login from './pages/Login/Login';
import AdminPanel from './pages/AdminPanel/AdminPanel';

function App() {
    return (
      <Router>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/admin" element={<AdminPanel />} />
          </Routes>
        </AuthProvider>
      </Router>
    );
}

export default App;
