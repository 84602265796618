import React, { useState, useEffect } from 'react';

import './Panel.css';

function Panel() {
    const [data, setData] = useState({
        courseDate: '',
        meetingDate: '',
        standardPrice: '',
        premiumPrice: '',
        additionalDriving: ''
    });

    useEffect(() => {
        // const apiUrl = process.env.REACT_APP_API_URL;
        fetch('https://oskbolid.pl/api.php', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ action: 'get_data' })
        })
        .then(response => response.json())
        .then(data => setData(data));
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData(prevData => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = () => {
        // const apiUrl = process.env.REACT_APP_API_URL;
        fetch('https://oskbolid.pl/api.php', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ action: 'update_data', data })
        })
        .then(response => response.json())
        .then(message => alert('Dane zaktualizowane!'));
    };

    return (
      <div className="login-container_panel">
        <form onSubmit={handleSubmit}>
          <h2>Panel Administratora</h2>
          <p>Wprowadź dane do aktualizacji:</p>
          <label htmlFor="courseDate">Data kursu:</label>
          <input type="text" id="courseDate" name="courseDate" value={data.courseDate} onChange={handleChange}/>

          <label htmlFor="meetingDate">Data spotkania:</label>
          <input type="text" id="meetingDate" name="meetingDate" value={data.meetingDate} onChange={handleChange}/>

          <label htmlFor="standardPrice">Cena standard:</label>
          <input type="text" id="standardPrice" name="standardPrice" value={data.standardPrice} onChange={handleChange}/>

          <label htmlFor="premiumPrice">Cena premium:</label>
          <input type="text" id="premiumPrice" name="premiumPrice" value={data.premiumPrice} onChange={handleChange}/>

          <label htmlFor="additionalDriving">Jazdy dodatkowe:</label>
          <input type="text" id="additionalDriving" name="additionalDriving" value={data.additionalDriving} onChange={handleChange}/>

          <button type="submit">
            Zapisz zmiany
          </button>
        </form>
      </div>
    );
}

export default Panel;
