import React from 'react'
import './PassingRate.css';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

export const PassingRate = () => {
  const renderNumber = (number) => {
    return (
      <VisibilitySensor partialVisibility offset={{ bottom: 100 }} component={'span'}>
        {({ isVisible }) => (
          <div style={{ height: 80 }} id='textCounter'>
            {isVisible ? <CountUp end={number} /> : null} %
          </div>
        )}
      </VisibilitySensor>
    )
  }

  const renderTitle = () => {
    return (
      <>
        <h2>Najwyższa zdawalność w mieście</h2>
        <h1 className='title' id="titleRating">{renderNumber(43)}</h1>
      </>
    );
  }

  return (
    <section className="passingRate">
      <div className="bg">
        {renderTitle()}
        <h3>Średnia z pozostałych szkół: 27%</h3>
        <h4>Sprawdź <a href="http://www.oskbolid.rsl.pl/" target="_blank" rel="noreferrer" id='linkToRanking'>ranking</a> zdawalności w Rudzie Śląskiej</h4>
      </div>
    </section>
  )
}