import React, { useContext, useEffect } from 'react';
import AuthContext from '../AuthContext';
import { useNavigate } from 'react-router-dom';

import Panel from './components/Panel'

function AdminPanel() {
    const { isLoggedIn } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoggedIn) {
            navigate('/login');
        }
    }, [isLoggedIn, navigate]);

    return (
        <><Panel /></>
    );
}

export default AdminPanel;
